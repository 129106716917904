import React from 'react';
import { arrayOf, string, shape, func } from 'prop-types';
import classnames from 'classnames';
import colornames from '../../../lib/colornames';
import Payment from '../../payment';
import componentEnhance from '../../../lib/component-enhance';
import onIframeModalResize from '../../../lib/on-iframe-modal-resize';
import getActionModal from '../../../lib/get-action-modal';
import PaymentSprites from './payment-sprites';

const namespace = 'ui-pdp-payment--md';

const PaymentSummary = ({
  action,
  className,
  icons,
  styles,
  title: { color, text, values },
  runCatchErrorBoundary,
  ...rest
}) => {
  try {
    return (
      <Payment
        action={
          action
            ? getActionModal(
                action,
                onIframeModalResize(
                  'vip:modal-content:loaded',
                  'height',
                  '.ui-pdp-iframe-modal',
                  '.andes-modal__header',
                ),
                false,
              )
            : null
        }
        className={classnames(namespace, className, colornames(color))}
        title={componentEnhance.jsx(text, values)}
        {...rest}
      >
        {styles && icons && icons.length > 0 && <PaymentSprites icons={icons} styles={styles} />}
      </Payment>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};
PaymentSummary.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string.isRequired,
  }),
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  icons: arrayOf(
    shape({
      id: string.isRequired,
      size: string,
    }),
  ),
  styles: shape({
    sprite_css: string.isRequired,
    picture_css: string.isRequired,
  }),
  text: string,
  title: shape({
    text: string.isRequired,
    color: string,
    values: shape(),
  }).isRequired,
  closeModalLabel: string,
  runCatchErrorBoundary: func,
};

PaymentSummary.defaultProps = {
  action: null,
  className: null,
  icon: null,
  icons: null,
  styles: null,
  text: null,
  closeModalLabel: null,
  runCatchErrorBoundary: () => {},
};

export default PaymentSummary;
