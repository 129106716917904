import { useMemo } from 'react';
import { COMPATS_CUSTOM } from '../compats.constants';
import { loadable } from 'nordic/lazy';
import { string, shape, func } from 'prop-types';

const GetCompatsWidget = (device, typeWidget) =>
  device === 'desktop' && typeWidget === COMPATS_CUSTOM
    ? loadable(() => import('./compats-desktop'))
    : loadable(() => import('./compats-mobile'));

const DeviceCompatWidget = ({ data, deviceType, onSubmitForm, onRemove, modifier }) => {
  const CompatsWidget = useMemo(() => GetCompatsWidget(deviceType, data?.type), [deviceType, data?.type]);

  return <CompatsWidget {...data} onSubmitForm={onSubmitForm} onRemove={onRemove} modifier={modifier} />;
};

DeviceCompatWidget.propTypes = {
  data: shape({
    type: string,
  }).isRequired,
  deviceType: string,
  onSubmitForm: func,
  onRemove: func,
  modifier: string,
};

export default DeviceCompatWidget;
