import React from 'react';
import { arrayOf, shape } from 'prop-types';
import classnames from 'classnames';
import colornames from '../../lib/colornames';
import componentEnhance from '../../lib/component-enhance';
import IconFactory from '../icons/factory-builder';
import { Label } from '../../prop-types';
import { isSummaryTitleValid } from '../../utils/summary';

const namespace = 'ui-pdp-summary-list';

const SummaryList = ({ list }) => (
  <ul className={namespace}>
    {list
      .filter(item => isSummaryTitleValid(item.title))
      .map(item => {
        const { title, state } = item;
        if (state === 'HIDDEN') {
          return null;
        }
        return (
          <li key={title?.text} className={classnames(`${namespace}__item`, colornames(title?.color))}>
            <p className={`${namespace}__text`}>
              {componentEnhance.jsx(title?.text || title, title?.values)}
              <span className={`${namespace}__item-icon`}>{title?.text_icon && IconFactory(title?.text_icon)}</span>
            </p>
          </li>
        );
      })}
  </ul>
);

SummaryList.propTypes = {
  list: arrayOf(
    shape({
      title: Label,
    }),
  ).isRequired,
};

export default SummaryList;
