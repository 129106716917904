import React from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import classNames from 'classnames';
import IconFactory from '../../icons/factory-builder';

const namespace = 'ui-pdp-seller';

const Description = ({ info, tagsReputationAlign, withTagsSeparatorBar }) => (
  <div
    className={classNames(`${namespace}__reputation-info`, {
      [`${namespace}__horizontal-items`]: tagsReputationAlign !== 'vertical',
      [`${namespace}__with-tag-separator-bar`]: withTagsSeparatorBar,
    })}
  >
    <ul className={`${namespace}__list-description`}>
      {info.map(item => (
        <li className={`${namespace}__item-description`} key={item.subtitle}>
          {item.icon && (
            <strong aria-hidden="true" className={`${namespace}__icon-description`}>
              {IconFactory(item.icon.id)}
            </strong>
          )}
          {item.title && <strong className={`${namespace}__sales-description`}>{item.title}</strong>}
          {item.subtitle && <p className={`${namespace}__text-description`}>{item.subtitle}</p>}
        </li>
      ))}
    </ul>
  </div>
);

Description.propTypes = {
  tagsReputationAlign: string,
  withTagsSeparatorBar: bool,
  info: arrayOf(
    shape({
      icon: shape({
        id: string,
      }),
      subtitlte: string,
      title: string,
    }),
  ).isRequired,
};

export default Description;
