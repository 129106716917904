import React from 'react';
import { arrayOf, bool, node, number, shape, string, func } from 'prop-types';
import classnames from 'classnames';
import { ActionModalUI, withBusinessLogic } from '@vpp-frontend-components/action-modal';
import ContainerInfo from './components/container-info';
import SellerStore from './components/seller-store';
import StyledLabel from '../styled-label';
import MediaAction from '../media/media-action';
import { trackEvent } from '../../lib/tracking';
import componentEnhance from '../../lib/component-enhance';
import CompatsWidget from '../compats-widget/block';
import { getActionModalConfig } from './utils/getActionModalConfig';
import IconFactory from '../icons/factory-builder';

const namespace = 'ui-pdp-seller';
const COMPATS_WIDGET_BLOCK = 'compats_widget_block';

const ActionModal = withBusinessLogic(ActionModalUI, getActionModalConfig);

// Si la primer palabra del nombre del vendedor tiene mas de 15 caracteres o el nombre es sin espacios y con mas de 15 caracteres lleva salto de linea
const hasLineBreak = text => {
  if (!text) {
    return false;
  }

  const splittedText = text.split(' ');

  return (splittedText.length === 1 && text.length > 15) || (splittedText.length > 1 && splittedText[0].length > 15);
};

const SellerInfo = ({
  className,
  titleValue,
  title,
  subtitles,
  hashId,
  seller,
  sellerInfo,
  url,
  showSellerLogo,
  seller_link,
  tooltip,
  brandTitle,
  components,
  onSubmitForm,
  onRemove,
  runCatchErrorBoundary,
}) => {
  try {
    const ActionModalRef = React.useRef(null);
    const renderMediaAction = action =>
      React.isValidElement(action) ? (
        action
      ) : (
        <MediaAction
          className={`${namespace}__link`}
          {...action}
          htmlTarget={action.html_target}
          onClick={() => {
            trackEvent(action.track);
          }}
        />
      );

    const content = (
      <>
        {brandTitle && (
          <div className={`${namespace}__brand-title-container`}>
            <StyledLabel
              className={`${namespace}__brand-title`}
              as="span"
              font_family={brandTitle.font_family}
              font_size={brandTitle.font_size}
              color={brandTitle.color}
              text={componentEnhance.jsx(brandTitle.text || brandTitle, brandTitle.values)}
            />
            {sellerInfo.icon && IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
          </div>
        )}
        {(title || seller_link) && (
          <button
            className={`${namespace}__link-trigger-button non-selectable`}
            type="button"
            onClick={() => ActionModalRef.current.openModal()}
            data-testid="action-modal-link"
          >
            {title && (
              <span
                className={classnames(
                  { [`${namespace}__label-sold`]: true },
                  { 'line-break': hasLineBreak(seller_link?.label?.text) },
                )}
              >
                {title}
              </span>
            )}
            {seller_link && !seller_link.target ? (
              <>
                <span className={classnames({ [`${namespace}__label-text-with-icon`]: !brandTitle && seller.icon })}>
                  {seller_link.label.text}
                  {!brandTitle && seller.icon && IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
                </span>
                <ActionModal
                  className={`${namespace}__link-trigger non-selectable`}
                  modalClassName="ui-pdp-modal-seller"
                  componentAsLabel={<StyledLabel as="span" {...seller_link.label} />}
                  modalTitle={seller_link.label.text}
                  modalUrl={hashId ? `#seller-info-${hashId}` : '#seller-info'}
                  url={url}
                  track={seller_link.track}
                  closeModalLabel={seller_link.close_modal_label}
                  autoHeight={false}
                  label={seller_link.label}
                  customRef={ActionModalRef}
                >
                  <ContainerInfo
                    {...sellerInfo}
                    {...{ officialStoreId: seller.officialStoreId, titleValue, brandTitle: sellerInfo.titleLabel }}
                  />
                </ActionModal>
              </>
            ) : (
              <>
                {seller_link && renderMediaAction(seller_link)}
                {!brandTitle && seller.icon && IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
              </>
            )}
          </button>
        )}
      </>
    );
    const hasCompats = components && components.find(component => component.type === COMPATS_WIDGET_BLOCK);
    return (
      <>
        <div className={classnames(`${namespace}`, className, { 'ui-pdp-seller__with-logo': !!showSellerLogo })}>
          <SellerStore
            titleValue={content}
            logo={showSellerLogo ? sellerInfo.logo : ''}
            sellerName={titleValue}
            subtitles={subtitles}
            tooltip={tooltip}
          />
        </div>
        {hasCompats && (
          <CompatsWidget
            components={components}
            onSubmitForm={onSubmitForm}
            onRemove={onRemove}
            namespace={namespace}
            modifier="mobile"
          />
        )}
      </>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

SellerInfo.propTypes = {
  className: string,
  hashId: string,
  seller: shape({
    officialStoreId: number,
  }).isRequired,
  sellerInfo: shape({
    extraInfo: arrayOf(
      shape({
        subtitle: string,
        title: string,
      }),
    ),
    powerSellerStatus: shape({ subtitle: string, title: string }),
    thermometer: shape({
      rank: number,
      info: arrayOf(
        shape({
          icon: shape({ id: string }),
          subtitle: string,
          title: string,
        }),
      ),
      defaultInfo: shape({
        subtitle: shape({
          color: string,
          text: string,
        }),
        title: shape({
          color: string,
          text: string,
        }),
      }),
    }).isRequired,
    subtitle: string,
    logo: string,
    header: string,
  }).isRequired,
  showSellerLogo: bool,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      weight: string,
      size: string,
    }),
  ),
  seller_link: shape({
    label: shape({
      text: string,
      color: string,
    }).isRequired,
    track: shape({ melidata_event: shape({}), analytics_event: shape({}) }),
  }).isRequired,
  title: string,
  titleValue: node,
  url: string,
  tooltip: shape({}),
  brandTitle: shape({
    text: string,
    color: string,
    font_size: string,
    font_family: string,
  }),
  components: arrayOf(shape({})),
  onRemove: func,
  onSubmitForm: func,
  runCatchErrorBoundary: func,
};

SellerInfo.defaultProps = {
  className: null,
  showSellerLogo: false,
  hashId: null,
  subtitles: null,
  title: null,
  url: null,
  tooltip: null,
  brandTitle: null,
  onRemove: null,
  onSubmitForm: null,
  components: null,
  runCatchErrorBoundary: () => {},
};

export default SellerInfo;
