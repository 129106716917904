import React from 'react';
import { shape, string, bool, func } from 'prop-types';
import classNames from 'classnames';
import { Label } from '../../prop-types';
import StyledLabel from '../styled-label';
import TooltipLegal from '../tooltip-legal/tooltip-legal.desktop';

const namespace = 'ui-pdp-stock-information';

const StockInformation = ({ className, title, subtitle, collapsible_tooltip, runCatchErrorBoundary }) => {
  try {
    return (
      <div className={classNames(namespace, className)}>
        <StyledLabel className={`${namespace}__title`} {...title} />
        <>
          {collapsible_tooltip ? (
            <TooltipLegal collapsibleTooltip={collapsible_tooltip}>
              {subtitle && <StyledLabel className={`${namespace}__subtitle`} {...subtitle} />}
            </TooltipLegal>
          ) : (
            subtitle && <StyledLabel className={`${namespace}__subtitle`} {...subtitle} />
          )}
        </>
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

StockInformation.propTypes = {
  collapsible_tooltip: shape({
    icon: shape({
      id: string,
      color: string,
      size: string,
    }),
    opened: bool,
    tooltip: shape({
      arrow_type: string,
      background_color: string,
      change_color: bool,
      close_color: string,
      closeable: bool,
      closed: bool,
      has_shadow: bool,
      state: string,
      storable: bool,
      subtitles: shape([
        {
          color: string,
          font_family: string,
          font_size: string,
          text: string,
        },
      ]),
      title: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
      type: string,
    }),
  }),
  className: string,
  title: Label.isRequired,
  subtitle: Label,
  runCatchErrorBoundary: func,
};

StockInformation.defaultProps = {
  className: '',
  subtitle: null,
  runCatchErrorBoundary: () => {},
};

export default StockInformation;
